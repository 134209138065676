@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');
.signup-page{
    background-color: #548CA8;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
}
.signup-container{
    background-color: white;
    width:400px;
    height: 600px;
    border-radius: 5px;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}