@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');
.login-page{
    background-color: #548CA8;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
}

.login-container{
    background-color: white;
    width:400px;
    height: 600px;
    border-radius: 5px;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.login-form>label{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color:black;
}

.login-form>input{
    border-radius: 5px;
    border: black,solid,thin;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    color:gray;
    font-size: 12px;
}