@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');
.loading-page{
    background-color: #548CA8;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
    flex-flow: column;
}
.loading-page>h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color:white;
}