.nav{
    display:flex;
    width:100%;
    background-color: #548CA8;
    flex-flow:column;
}
.nav__wrapper-head{
    display:flex;
    margin:auto;
    height:20%;
    width:100%;
}
.nav__link-home{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.nav__link-home>h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color:white;
    text-decoration: none;
    font-size: 100%;
}
.nav__link-home:hover{
    cursor: pointer;
    text-decoration: none;
}
.nav__wrapper-body{
    display:flex;
    height:80vh;
    width: 100%;
    flex-flow: column;
}
.nav__link{
    padding: 5% 0;
    text-decoration: none;
}
.nav__link:hover{
    cursor: pointer;
    text-decoration: none;
    background-color: #265973;
}
.nav__link>p{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color:white;
    text-decoration: none;
    font-size: 14px;
    margin:auto 20px;
    line-height: 20px;
}
.nav__link>button{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color:white;
    text-decoration: none;
    font-size: 14px;
    margin:auto 20px;
    line-height: 20px;
}
