.home-page{
    background-color: whitesmoke;
    display:grid;
    /* flex-flow: column; */
    grid-template-rows: 25% 75%;
}
.home-page__title{
    display:flex;
    justify-content: center;
    align-items: center;
}
.home-page__h > {
    margin:auto;
    display: flex;
}
.home-page__body{
    display: flex;
    flex-flow: column;
}
.home-page__body-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-page__body-content{
    display: flex;
    margin: 20px 5% 20px 5%;
}
.home-page__body-content > div {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    gap: 10px;
}
